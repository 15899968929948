.baccarat-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    height: 650px;
}

.baccarat {
    background-image: url("https://media.istockphoto.com/id/672556284/photo/green-felt-and-playing-chips-abstract-background.jpg?s=612x612&w=0&k=20&c=qCon6tBWqG3-dLMxVkTlOA7d5QKD6KC8W32p3H2N-MQ=");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
}

.hand {
    margin: 20px 0;
}

.baccarat-cards {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    position: relative;
}

.baccarat-card {
    width: 80px;
    height: 120px;
    background-color: white;
    color: black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    position: relative;
    opacity: 0;
    transform: translateX(100vw) rotate(-360deg);
    animation: flyIn 0.5s forwards;
    animation-delay: var(--delay);
}

.baccarat-card.third-card {
    transform: translateX(100vw) rotate(-360deg) rotate(90deg);
}

.baccarat-card.fly-out {
    animation: flyOut 0.5s forwards;
}

.btn-col {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
}

.play-btn {
    width: 400px;
}

@media (max-width: 768px) {
    .buttons {
        flex-direction: column;
        align-items: center;
    }
}

.baccarat-message {
    font-size: 24px;
    margin-top: 20px;
}

.baccarat-head {
    text-align: center;
    margin: auto;
}

@keyframes flyIn {
    to {
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
}

@keyframes flyOut {
    to {
        opacity: 0;
        transform: translateX(-100vw) rotate(360deg);
    }
}

@media (max-width: 768px) {
    .baccarat-container {
        height: 550px;
    }

    #baccarat-amount {
        width: 86.5%;
    }
}
