/* FeatureCards.css */

.feature-cards {
    display: flex;
    justify-content: space-around;
    /* margin-top: 20px; */
    border-radius: 10px;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
}

.feature-card {
    flex: 1;
    background-color: #031B22;
    color: #ffffff;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #00c8ff6b;
    margin: 0 5px;
    text-align: center;
    transition: transform 0.2s ease;
}

.feature-card:hover {
    transform: scale(1.025);
}

.card-icon {
    font-size: 30px;
    margin-bottom: 10px;
    color: #00d1ff;
    /* Light blue color for icons */
}

.feature-card h3 {
    font-size: 1.25em;
    margin-bottom: 10px;
}

.feature-card p {
    font-size: 0.9em;
    color: #c0c9d1;
    /* Softer text color for description */
}

.card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
    margin-top: 15px;
}

.card-tags span {
    background-color: #2d3b44;
    color: #00d1ff;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8em;
}

@media (max-width: 768px) {
    .feature-cards {
        width: 90%;
        margin-left: 5%;
    }
}
