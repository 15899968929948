.dropdown-btn {
    display: flex;
    /* align-items: left; */
    /* width: fit-content; */
    padding: 10px;
    padding-bottom: 14px;
    /* width: 97.5%; */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    cursor: pointer;
}

.toggle-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}
/* 
.button-open {
    border: rgba(147, 197, 253) 2px solid;
} */
