/* Mines */
#main {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mines {
    height: 650px !important;
    /* max-width: 400px; */
    /* height: 400px !important; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 50px;
}

.grid-button {
    perspective: 1000px;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.25s;
}

.grid-button:hover {
    background-color: #666;
    border-radius: 5px;
    transform: scale(1.06);
}

.grid-button div {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: transform 0.6s;
}

.grid-button .front {
    background-color: rgb(17, 167, 177);
    color: #fff;
    transform: rotateY(0deg);
}

.grid-button .back {
    /* background-color: #222; */
    background-color: rgb(14, 116, 123);
    color: #fff;
    transform: rotateY(180deg);
}

.grid-button.flipped .front {
    transform: rotateY(180deg);
}

.grid-button.flipped .back {
    transform: rotateY(0deg);
}

.grid-button.exploded {
    animation: explosion 1s forwards;
}

@keyframes explosion {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .mines {
        display: flex;
        flex-direction: column; 
        align-items: center;
        width: 100%; 
        height: 500px !important;
    }
}
