.sidenav-main-container {
    display: flex;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 60px;
    /* background-color: #031b22; */
    background-color: #03151B;
    color: white;
    transition: width 0.3s ease;
    overflow: hidden;
    z-index: 1001;
}

.sidebar.open {
    width: 180px;
}

.content {
    margin-left: 80px;
    width: calc(100% - 80px);
    transition: margin-left 0.3s ease, width 0.3s ease;
}

.sidebar-open .content {
    margin-left: 250px;
    width: calc(100% - 250px);
}

.sidebar-header {
    background-color: #031b22;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
    padding-bottom: 0px;
    height: 80px;
}

.sidebar-header h2 {
    margin: 0;
}

/* Popout submenu container */
.popout-menu {
    position: fixed;
    left: 57.5px;
    top: 160px;
    background-color: #031B22;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: none;
    z-index: 1001;
    overflow: visible;
    box-shadow: 0 4px 8px rgba(137, 137, 137, 0.2);
}

/* Hover to show the popout menu */
.sidebar-item:hover .popout-menu {
    display: block;
}

/* Only show the popout when the sidebar is closed */
.sidebar:not(.open) .sidebar-item:hover .popout-menu {
    display: block;
}

.sidebar-item {
    padding: 1rem;
    text-align: left;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease;
    border-radius: 10px;
    background-color: #031116;
    margin-bottom: 5px;
}

.sidebar-item:hover,
.sidebar-item.active {
    background-color: #00bfff6b;
    /* border: 5px; */
}

.submenu {
    padding-left: 1.5rem;
    list-style-type: none;
    margin-top: 0.5rem;
}

.submenu li {
    padding: 0.5rem 0;
}

.submenu li a {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.submenu li a:hover {
    color: #00bfff;
}

/* Popout menu items */
.popout-menu a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 0.5rem 1rem;
    /* background-color: #031116; */
    background-color: #031B22;
    border-radius: 5px;
    margin: 8px;
}

.popout-menu a:hover {
    background-color: #1698c449;
}

.game-icons {
    margin-right: 6px;
}

#sidebar-btn {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
    margin-left: 2px;
    margin-bottom: 26px;
    margin-top: 16px;
    transition: transform 0.3s ease;
}

#sidebar-btn.rotate {
    transform: rotate(-90deg);
}

.sidebar-list {
    list-style: none;
    padding: 0;
}

.sidebar-items {
    /* background-color: #031116; */
    background-color: #03151B;
    box-sizing: border-box;
    border-radius: 20px;
    border: 6px solid #03151B;
}

/* .top {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.bottom {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
} */

.sidebar-link {
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.sidebar-link span {
    margin-left: 1.5rem;
}

.sidebar-link i {
    min-width: 20px;
}

.content {
    margin-left: 80px;
    padding: 2rem;
    width: calc(100% - 80px);
    transition: margin-left 0.3s ease, width 0.3s ease;
}

.sidebar-open .content {
    margin-left: 250px;
    width: calc(100% - 250px);
}

/* Desktop Sidebar */
/* @media (min-width: 769px) {
    .sidebar {
        top: 0;
        left: 0;
        height: 100vh;
        width: 60px;
        flex-direction: column;
        box-shadow: none;
        transition: width 0.3s ease;
    }

    .sidebar.open {
        width: 210px;
    }
} */

/* Mobile Sidebar */
@media (max-width: 768px) {
    .sidebar {
        display: flex;
        position: fixed;
        top: 80px;
        width: 100%;
        height: 70px;
        justify-content: space-around;
        align-items: center;
        background-color: #03151B;
        box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.13);
        z-index: 1001;
    }

    .sidebar-items {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        list-style: none;
        gap: 20%;
    }

    /* Hide content behind sidebar on mobile */
    .content {
        margin-bottom: 60px;
    }

    /* Sidebar item adjustments for bottom bar */
    .sidebar-item {
        flex: 1;
        text-align: center;
        margin: 0;
        background-color: #03151B;
    }

    .sidebar-item:hover {
        background-color: #03151B;
    }

    /* Icon and text styling for bottom bar */
    .sidebar-item i {
        font-size: 20px;
        display: block;
    }

    .sidebar-item span {
        display: block;
        font-size: 16px;
        margin-top: 4px;
    }

    /* Remove the popout menu for mobile */
    .popout-menu {
        display: none;
    }

    /* Hide header and extra padding/margins */
    .sidebar-header,
    #sidebar-btn {
        display: none;
    }
}

@media (max-width: 512px) {
    .sidebar-items {
        gap: 1%;
    }
}