.blackjack-container {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    height: 650px;
}

.blackjack {
    background-image: url("https://media.istockphoto.com/id/672556284/photo/green-felt-and-playing-chips-abstract-background.jpg?s=612x612&w=0&k=20&c=qCon6tBWqG3-dLMxVkTlOA7d5QKD6KC8W32p3H2N-MQ=");
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: scroll;
    background-size: cover;
}

.hand {
    margin: 20px 0;
}

.blackjack-cards {
    display: flex;
    justify-content: center;
    margin: 10px 0;
    position: relative;
}

.blackjack-card {
    width: 80px;
    height: 120px;
    background-color: white;
    color: black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -40px;
    position: relative;
    opacity: 0;
    transform: translateX(100vw) rotate(-360deg);
    animation: flyIn 0.5s forwards;
}

.blackjack-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
}

.blackjack-card:first-child {
    margin-left: 0;
}

.btn-col {
    display: flex;
    /* gap: 5px; */
    margin-bottom: 10px;
}

.play-btn {
    width: 400px;
}

@media (max-width: 768px) {
    .buttons {
        flex-direction: column;
        align-items: center;
    }
}

.blackjack-message {
    font-size: 24px;
    margin-top: 20px;
}

.blackjack-head {
    text-align: center;
    margin: auto;
}

@keyframes flyIn {
    to {
        opacity: 1;
        transform: translateX(0) rotate(0);
    }
}

@keyframes flyOut {
    from {
        opacity: 1;
        transform: translateX(0) rotate(0);
    }

    to {
        opacity: 0;
        transform: translateX(-100vw) rotate(360deg);
    }
}

@media (max-width: 768px) {
    .blackjack-container {
        height: 550px;
    }

    #blackjack-amount {
        width: 87.5% !important;
    }

    .blackjack-play-button {
        width: 100% !important;
    }
}

/*========================================================
                    Button Styles 
========================================================*/

.dir-control {
    --borderWidth: 2;
    --buttonColor: #c7edef;
    --bg: #0d0d0d;
    --backdrop: transparent;
    --fontSize: 1;
    --horizontalPadding: 16;
    --verticalPadding: 8;
    background: var(--backdrop);
    border: calc(var(--borderWidth) * 1px) solid var(--buttonColor);
    border-radius: 9999px;
    text-align: center;
    line-height: 24px;
    color: var(--textColor, var(--buttonColor));
    cursor: pointer;
    font-size: calc(var(--fontSize) * 1rem);
    font-weight: bold;
    font-family: sans-serif;
    outline: transparent;
    padding: calc(var(--verticalPadding) * 1px) calc(var(--horizontalPadding) * 1px);
    position: relative;
    text-decoration: none;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    transition: transform 0.2s;
    transform: translate(0, calc(var(--y, 0) * 1%)) scale(var(--scale, 1));
}

.dir-control:hover {
    --y: -5;
    --scale: 1.05;
}

.dir-control:active {
    --y: 0;
    --scale: 0.95;
}

.dir-control span {
    -webkit-clip-path: var(--clip);
    bottom: calc(var(--borderWidth) * -1px);
    clip-path: var(--clip);
    left: calc(var(--borderWidth) * -1px);
    position: absolute;
    right: calc(var(--borderWidth) * -1px);
    top: calc(var(--borderWidth) * -1px);
    z-index: 1;
}

.dir-control span:nth-of-type(1):hover,
.dir-control span:nth-of-type(2):hover,
.dir-control span:nth-of-type(3):hover,
.dir-control span:nth-of-type(4):hover {
    --clip: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    z-index: 2;
}

.dir-control span:nth-of-type(1):hover~b:nth-of-type(1),
.dir-control span:nth-of-type(2):hover~b:nth-of-type(2),
.dir-control span:nth-of-type(3):hover~b:nth-of-type(3),
.dir-control span:nth-of-type(4):hover~b:nth-of-type(4) {
    --clip: inset(0 0 0 0);
}

.dir-control span:nth-of-type(1) {
    --clip: polygon(0 0, 100% 0, 50% 50%, 50% 50%);
}

.dir-control span:nth-of-type(2) {
    --clip: polygon(100% 0, 100% 100%, 50% 50%);
}

.dir-control span:nth-of-type(3) {
    --clip: polygon(0 100%, 100% 100%, 50% 50%);
}

.dir-control span:nth-of-type(4) {
    --clip: polygon(0 0, 0 100%, 50% 50%);
}

.dir-control b {
    -webkit-clip-path: var(--clip);
    background: var(--slideColor, var(--buttonColor));
    border: calc(var(--borderWidth) * 1px) solid var(--buttonColor);
    bottom: calc(var(--borderWidth) * -1px);
    font-weight: bold;
    clip-path: var(--clip);
    color: var(--bg, #fafafa);
    left: calc(var(--borderWidth) * -1px);
    padding: calc(var(--verticalPadding) * 1px) calc(var(--horizontalPadding) * 1px);
    position: absolute;
    right: calc(var(--borderWidth) * -1px);
    top: calc(var(--borderWidth) * -1px);
    transition: -webkit-clip-path 0.25s ease;
    transition: clip-path 0.25s ease;
    transition: clip-path 0.25s ease, -webkit-clip-path 0.25s ease;
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dir-control b:nth-of-type(1) {
    --clip: inset(0 0 100% 0);
}

.dir-control b:nth-of-type(2) {
    --clip: inset(0 0 0 100%);
}

.dir-control b:nth-of-type(3) {
    --clip: inset(100% 0 0 0);
}

.dir-control b:nth-of-type(4) {
    --clip: inset(0 100% 0 0);
}

.dir-control--secondary {
    --buttonColor: #fff;
    --bg: #0d0d0d;
}

.dir-control--filled {
    --backdrop: #c7edef;
    --slideColor: #0d0d0d;
    --textColor: #0d0d0d;
    --bg: #c7edef;
}