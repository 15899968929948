.header {
    position: fixed;
    top: 0;
    /* left: 0; */
    height: 80px;
    width: 100%;
    padding: 10px 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(218, 216, 216, 0.021);
    background-color: #031b22;
    /* background-color: #031116; */
}

.logo {
    display: flex;
    align-items: center;
    /* position: sticky; */
    margin-left: 5%;
    z-index: 2000;
}

.roulo-logo {
    width: 300px;
    margin-left: -40%;
    height: auto;
    max-height: 100px;
    object-fit: contain !important;
    z-index: 2000;
}

.deposit-container {
    /* background-color: #333333; */
    padding: 10px;
    border-radius: 20px;
    gap: 10px;
}

.deposit,
.user-balance {
    background-color: #00c3ff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, ease 0.2s;
}

.deposit:hover {
    background-color: #0eabdb;
}

.user-balance {
    background-color: #1bb123;
}

.navbar {
    display: flex;
    gap: 20px;
    align-items: center;
    margin-right: auto;
}

.navbar-username {
    font-size: 22px;
    color: white;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.user-dropdown-menu {
    position: absolute;
    top: 110%;
    right: -10px;
    transform: translateX(-50%);
    background-color: #062A35;
    /* background: linear-gradient(180deg, #0c6884, #021a22); */
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    width: 125px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.user-dropdown-item {
    display: block;
    padding: 10px;
    color: white;
    text-decoration: none;
    border-radius: 10px;
}

.user-dropdown-item:hover {
    background-color: #031b22;
    border-radius: 10px;
    margin-left: auto;
}

.fas.fa-caret-down {
    margin-left: auto;
}

.fas.fa-caret-down.open {
    transform: rotate(180deg);
}

.user-icon {
    margin-right: 6px;
}

.navbar a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 15px;
    color: #828282;
    font-weight: 300;
    margin-left: 40px;
}

.navbar a::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 0;
    height: 2px;
    background: #828282;
    transition: .3s;
}

.navbar a:hover::before {
    width: 100%;
}

.rightNav {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-end;
    position: sticky;
    /* margin-right: 1%; */
}

.middleNav {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    position: sticky;
    /* margin-right: 120%;  */
}

.btn-reg {
    /* background: linear-gradient(45deg, #031B22, #09566d); */
    background-color: #1888ff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s, box-shadow 0.2s;
    margin-left: 10px;
    margin-right: 50px;
}

.btn-reg:hover,
.rightNav .btn-reg:focus {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.btn-username {
    padding: 5px 10px 5px 12px;
    background: linear-gradient(45deg, #031B22, #09566d);
}

.btn-username:hover {
    transform: none;
}

.btn-in {
    display: inline-flex;
    /* Use flexbox to layout contents */
    align-items: center;
    /* Center items vertically */
    justify-content: center;
    /* Center items horizontally */
    background: transparent;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    white-space: nowrap;
    /* Prevent wrapping */
}

.btn-in:hover,
.rightNav .btn-in:focus {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Hamburger menu */
.menu-toggle {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

/* Mobile-only styling */
@media (max-width: 768px) {
    .nav-icons,
    .nav-actions {
        flex-direction: row;
        gap: 15px;
    }

    .navbar-container {
        flex-direction: row;
    }

    .logo {
        font-size: 18px;
        margin-left: 10%;
    }

    .header {
        height: 80px;
        width: 110%;
        margin-left: -40px;
    }

    .btn-username,
    .navbar-username {
        margin-right: 40%;
    }
}

@media (max-width: 512px) {
    .rightNav{
        margin-left: -40%;
    }

    .logo {
        padding-left: 20px;
    }
}

@media (max-width: 400px) {
    .rightNav{
        margin-left: -51%;
    }
}



/* @media (max-width: 768px) {
    .menu-toggle {
        display: block;
        position: absolute;
        right: 20px;
    }

    .navbar {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        height: calc(100vh - 60px);
        background: #333333;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(-100%);
        transition: transform 0.3s ease-in-out;
        visibility: hidden;
        padding: 20px;
    }

    .navbar.open {
        transform: translateY(0);
        visibility: visible;
    }

    .navbar a {
        margin: 20px 0;
        font-size: 25px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border-radius: 10px;
    }

    .navbar a:hover {
        background-color: #444444;
    }

    .navbar a i {
        margin-right: 15px;
        font-size: 24px;
    }

    .rightNav {
        visibility: hidden;
    }

    .logo {
        font-size: 28px;
        color: white;
        font-weight: 700;
        margin-left: -20px;
    }

} */