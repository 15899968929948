.card {
    background: linear-gradient(to right, #0c6884, #021a229c 80%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    text-align: center;
    width: 96.5%;
    height: 30vh;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 5px;
}

.card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
}

.card img {
    width: auto;
    height: 100%;
}

@media (max-width: 480px) {
    .card {
        width: 100%;
        /* Adjust the card width to take the full width of the container */
        height: 100%;
        /* Make height automatic to maintain aspect ratio */
        margin: 10px 20px;
        /* Add some vertical margin for spacing */
        padding: 10px;
        /* Optional: Add padding inside the card */
    }

    .card img {
        width: 100%;
        /* Adjust image width to be full width of the card */
        height: auto;
        /* Adjust image height to maintain aspect ratio */
    }
}