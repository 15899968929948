/* Keno.css */
.keno-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px auto;
    /* Center horizontally */
    height: 80vh;
    /* Adjust height as needed */
    max-width: 1200px;
    /* Set max-width to center the content */
    box-sizing: border-box;
    margin-top: 120px;
}

.keno-grid-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    /* margin-top: 20px; */
    padding: 10px;
}

.keno-grid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
    margin-bottom: 20px;
    width: 800px;
    /* Increase width to make buttons larger */
}

.keno-number {
    width: 100%;
    /* Adjust width to fill the cell */
    height: 80px;
    /* Increase height to make buttons larger */
    background-color: #073A49;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    /* Add transform for hover effect */
}

.keno-number:hover:not(.selected):not(.disabled) {
    background-color: #0b556b;
    /* Slightly grey */
    transform: scale(1.1);
    /* Pop out effect */
}

.keno-number.selected {
    background-color: #0aa9b4;
}

.keno-number.result {
    background-color: #ff0;
}

.keno-number.disabled {
    background-color: #2b4c56;
    cursor: not-allowed;
}

/* .keno-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 20px;
    background-color: #333;
    border-radius: 10px;
    height: 550px;
    box-sizing: border-box;
    margin-right: 40px;
}

.game-mode {
    display: flex;
    margin-bottom: 10px;
}

.game-mode button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.game-mode button.active {
    background-color: #555;
} */

.control-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    justify-content: center;
}

.control-panel label {
    color: #fff;
    margin: 5px 0;
}

.control-panel input,
.control-panel select {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: none;
}

.auto-pick,
.clear-table {
    flex: 10;
    padding: 10px 20px;
    margin: 8px 0;
    margin-left: 5px;
    /* margin-right: -5px; */
    background-color: #00EEFF;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.keno-instructions,
.keno-probabilities {
    width: 800px;
    padding: 20px;
    background-color: black;
    /* background-color: #073A49; */
    color: #fff;
    text-align: center;
    margin-top: 20px;
    border-radius: 5px;
    box-sizing: border-box;
}

.keno-instructions p,
.keno-probabilities h3 {
    margin: 0;
}

.keno-probabilities h3 {
    margin-bottom: 10px;
}

.probabilities-container {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 5px;
    margin-top: 10px;
    min-width: 100%;
}

.probability-box {
    background-color: #073A49;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
    /* min-width: 100%; */
    text-align: center;
    cursor: default;
}

/* .tile-counter-container {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    margin-top: 10px;
}

.tile-counter {
    background-color: #073A49;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-size: 14px;
    min-width: 120px;
    text-align: center;
    cursor: default;
    width: 10px;
} */

.side-panel #keno-button {
    width: 120%;
    padding: 8px;
    background-color: #5d5b5b;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.side-panel #keno-button:hover {
    background-color: #a2a0a0;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .keno-grid-container {
        width: 100%;
    }

    .keno-grid {
        width: 100%;
    }

    .keno-number {
        height: 60px;
    }

    .keno-instructions,
    .keno-probabilities {
        width: 100%;
    }

    .probabilities-container {
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
    }

    .probability-box {
        padding: 8px;
        font-size: 12px;
    }

    #keno-auto-btns {
        width: 98.5% !important;
    }
}