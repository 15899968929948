/* styling for the Game controler */

.game-container {
    /* color: #031116; */
    display: flex;
    /* height: 650px; */
    height: auto !important;
    width: 1202px;
    /* border-radius: 10px; */
    margin-right: 12rem;
    margin-bottom: 36px;
    margin-left: 12rem;
    background-color: #031116 !important;
    /* background-color: white !important; */
}

.side-panel {
    border-radius: 10px;
    box-sizing: border-box;
    text-align: left;
    padding: 10px;
    padding-right: 60px;
    height: 60%;
    background-color: #073a49;
}

.game-panel {
    border-radius: 10px !important;
    box-sizing: border-box;
    background-color: #031B22 !important;
    display: flex;
    height: 70% !important;
    width: 830px !important;
    /* margin-top: 5%; */
}

.side-panel h2 {
    color: #fff;
    padding-bottom: 12px;
    text-align: left;
    margin: auto;
}

.side-panel label {
    color: #828282;
    font-size: 14px;
}

.side-panel input,
.side-panel select {
    width: 115%;
    padding: 8px;
    border-radius: 5px;
    border: none;
    margin-top: 2px;
    margin-left: 5px;
    background-color: #111010;
    color: white;
    cursor: pointer;
}

.side-panel #play-button {
    width: 115%;
    padding: 12px;
    margin-left: 5px;
    margin-bottom: 10px;
    background-color: rgb(0, 238, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.side-panel #cashout-button {
    width: 115%;
    /* margin-top: 10px; */
    margin-left: 5px;
    margin-bottom: 10px;
    padding: 12px;
    background-color: rgb(0, 238, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.winnings {
    margin-left: 40%;
    margin-top: 12px;
    margin-bottom: 12px;
}

.side-inputs {
    margin-bottom: 10px;
}

.auto-clear-btns {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 115%;
}

.side-inputs input,
.side-inputs select {
    cursor: pointer;
    background-color: #031B22;
    border-color: #0c5267;
    border-radius: 6px;
    /* padding: 6px; */
}

/* Game Description Styles */
/* Game description card styling */
.game-description-card {
    display: flex;
    align-items: center;
    background-color: #031B22;
    border-radius: 20px;
    padding: 20px;
    color: white;
    width: 100%;
    max-width: 1202px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.game-description-image {
    width: 150px;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
}

.game-description-content {
    flex-grow: 1;
    text-align: left;
}


.game-description-header h2 {
    margin: 0;
    font-size: 18px;
}

.description-button {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
}

.description-button:hover {
    background-color: #0056b3;
}

.game-description-content h2 {
    margin-bottom: 10px;
    color: #ffffff;
}

.game-description-content p {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 65px;
}

@media (max-width: 768px) {
    .game-container {
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        padding: 10px;
        margin-top: 100px;
    }

    .side-panel {
        width: 100%;
        padding: 10px;
        margin-left: 3.5%;
        order: 2;
        border-radius: 0 0 5px 5px;
    }

    .game-panel {
        width: 100% !important;
        margin: 0 auto;
        padding: 10px;

        order: 1;
        border-radius: 5px 5px 0 0 !important;
    }

    .side-panel input,
    .side-panel select,
    .side-panel #play-button,
    .side-panel #cashout-button {
        width: 97.5%;
    }

    .game-description-card {
        width: 97.5%;
        margin-top: 10px;
        margin-bottom: -60px;
    }

    .auto-clear-btns{
        width: 99% !important;
    }
}