/* Crash.css */
.side-panel {
  margin-right: 20px;
}

.crash {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .crash-canvas {
  padding: 10%;
} */

.game-canvas {
  border: 1px solid #000;
}

.bottom-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.crash-message,
.cash-out-message,
.countdown {
  font-size: 20px;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .crash-canvas{
    width: 530px;
    margin-bottom: -10px;
  }
}