.plinko {
    display: flex;
    flex-direction: column;
}

.plinko-canvas {
    margin-top: -20px;
    margin-bottom: -40px;
}

.plinko-odds {
    gap: 5px;
    width: 75%;
    /* background-color: white; */
    padding: 0 12px 0 12px;
    margin-bottom: 24px;
}

.multiplier-hit {
    position: absolute;
    top: 27.5%;
    right: 16%;
    color: yellow;
    font-size: 1.5em;
    font-weight: bold;
}

.odds-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.odds-box {
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    color: black;
}

.odds-box:hover {
    background-color: initial;
    border: 2px solid;
    border-color: white;
}

.plink-box {
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5px;
    color: black;
}

@media (max-width: 768px) {
    .odds-box {
        height: 30px;
        font-size: 11px;
    }
}