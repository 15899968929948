/* Dice.css */

.dice-side-panel {
    height: 37.5%;
    width: 30%;
}

.slider-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10%;
    /* margin-bottom: 25%; */
}

.slider-container {
    background-color: #073a49;
    padding: 15px;
    margin: auto;
    margin-bottom: 12px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 5px; */
    width: 77.5%;
}

.winning-odds {
    display: flex;
    background-color: #073a49;
    padding: 20px;
    border-radius: 10px;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    width: 90%;
}

.wo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wo-item-input {
    width: 100%;
    color: white;
    background-color: #031B22;
    border-color: #0c5267;
    border-radius: 6px;
    padding: 6px;
}

.wo-item-input:hover {
    border-color: #106d89;
}

.slider-container input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 12.5px;
    outline: none;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background: linear-gradient(to right, #ff0000, #00ff00);
}

.slider-container input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    background: #4a90e2;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.slider-container input[type="range"]::-moz-range-thumb {
    width: 30px;
    height: 30px;
    background: #4a90e2;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.slider-container input[type="range"]::-ms-thumb {
    width: 30px;
    height: 30px;
    background: #4a90e2;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    width: 72.5%; /* Need to adjust width of lables so slider lines up w number */
    color: #fff;
    font-size: 16px;
}

@media (max-width: 768px) {
    .dice-side-panel {
        width: 100%;
        margin-bottom: 20px;
    }

    .controls-and-slider {
        flex-direction: column;
    }

    .winning-odds {
        flex-direction: column;
        width: 150%;
        margin-left: 40%;
    }

    .slider-container {
        width: 140%;
    }

    .slider-labels {
        width: 125%;
        margin-left: 43%;
    }
}