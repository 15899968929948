/* Basic reset and box-sizing */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.category-nav-wrapper {
  /* background: linear-gradient(180deg, #0b4f63, #021a22); */
  background-color: #062a35;
  padding: 3px 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  margin-top: 20px;
  max-width: 100%;
  overflow-x: auto;
  /* overflow: hidden; */
}

.category-nav {
  list-style: none;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  /* gap: 15px; */
  width: 100%;
}

.category-nav-item {
  margin: 10px 10px;
  flex: 1;
}

.category-nav-link {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
  white-space: nowrap;
}

.category-nav-link:hover {
  background-color: #1552a3;
  /* Adjust for hover and active state */
  color: #ecf0f1;
}

.category-nav-link i {
  margin-right: 8px;
  font-size: 18px;
  /* Adjust icon size */
}

/* Icon styles can be customized here or use font-awesome or similar libraries */
.icon-lobby::before {
  content: '🎮';
}

.icon-stake-originals::before {
  content: '🔥';
}

.icon-slot-games::before {
  content: '🎰';
}

.icon-table-games::before {
  content: '♠️';
}

@media (max-width: 768px) {
  .category-nav-wrapper {
    width: 90%;
    margin-left: 5%;
    overflow-x: auto;
  }

  .category-nav {
    flex-wrap: nowrap; /* Prevents items from wrapping to multiple lines on smaller screens */
    justify-content: flex-start;
  }

  .category-nav-item {
    flex: 1;
    /* min-width: 100px; */
    text-align: center;
    min-width: auto;
  }
}