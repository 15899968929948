.settings-container {
    display: flex;
    flex-direction: column;
    min-height: 100%; 
    width: 75%; 
    box-sizing: border-box; 
    margin-top: -25px;
    /* margin: 0 5%; */
}

.settings-sidebar {
    width: 100%; 
    display: flex;
    /* background-color: #031B22; */
    background-color: #064152;
    padding: 10px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); /* Slight shadow to differentiate */
    border-radius: 10px 10px 0 0;
}

.settings-sidebar ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: auto;
}

.settings-sidebar ul li {
    padding: 15px;
    padding-right: 20px;
    height: 40px;
    margin: 10px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: auto; /* Ensure the width is flexible */
    border-radius: 20px;
}

.settings-sidebar ul li:hover,
.settings-sidebar ul li.active {
    background-color: #1099c3;
    border-radius: 20px;
}

.settings-sidebar ul li i {
    margin-right: 10px;
}

.settings-section {
    /* background-color: #064152; */
    background-color: #031B22;
    padding: 20px;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.settings-section h2 {
    color: #0FB8EC;
    padding-bottom: 12px;
}

.settings-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.settings-field label {
    display: block;
    margin-bottom: 5px;
    color: #fff;
    text-align: left;
}

.settings-field input {
    width: 100% !important;
}

.settings-field input,
.settings-field select {
    width: 75%;
    align-content: left;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

/* #email-address {
    width: 80% !important;
} */

.linked-accounts {
    align-items: left;
}

.verify-btn {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #0c6884;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.verify-btn:hover {
    background-color: #0faddd;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #654bb9;
}

input:checked + .slider:before {
    transform: translateX(20px);
}

.account-closure {
    text-align: left;
    margin-top: 30px;
}

.close-account-btn {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #d62234;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.close-account-btn:hover {
    background-color: #e74c3c;
}

/* Security Settings Styles */
.security-settings-label {
    color: #fff;
    margin-bottom: 8px;
    /* font-weight: bold; */
}

.security-settings-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: none;
    background-color: #1F2A36;
    border: 1px solid #2E3B4E;
    color: #fff;
}

.security-btn {
    background-color: #0c6884;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.2s ease;
}

.security-btn:hover {
    background-color: #0faddd;
}

.multi-device-buttons {
    display: flex;
    gap: 10px;
}

.logout-btn {
    background-color: #d62234;
}

.logout-btn:hover {
    background-color: #e74c3c;
}

/* Verification Settings Styles */
.verification-section h2 {
    color: #0FB8EC;
    padding-bottom: 20px;
}

.verification-tabs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.verification-tab {
    background-color: #2E3B4E;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    width: 23%;
}

.verification-tab.active {
    background-color: #00C851;
}

.verification-tab h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.verification-tab p {
    margin: 5px 0 0;
    font-size: 14px;
}

.verification-details p {
    color: #aaa;
    margin-bottom: 20px;
}

.verification-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.verification-form-row {
    display: flex;
    gap: 20px;
}

.verification-form-field {
    flex: 1;
}

.verification-form-field label {
    display: block;
    color: #ffffff;
    margin-bottom: 5px;
}

.verification-form-field input,
.verification-form-field select,
.settings-field input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #2E3B4E;
    background-color: #1F2A36;
    color: #ffffff;
}

.save-btn {
    background-color: #0c6884;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    transition: background-color 0.2s ease;
}

.save-btn:hover {
    background-color: #0faddd;
}